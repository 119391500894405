@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: white;

  --spacing-small: 8px;
  --spacing-medium: 16px;
  --spacing-large: 24px;
  --teal-50: #e7f6f6;
  --teal-100: #b4e2e2;
  --teal-200: #90d3d3;
  --teal-300: #5ebfbf;
  --teal-400: #3eb3b3;
  --teal-500: #0ea0a0;
  --teal-600: #0d9292;
  --teal-700: #0a7272;
  --teal-800: #085858;
  --teal-900: #064343;

  --orange-50: #fef4ec;
  --orange-100: #fddcc5;
  --orange-200: #fccba9;
  --orange-300: #fbb482;
  --orange-400: #faa56a;
  --orange-500: #f98f45;
  --orange-600: #e3823f;
  --orange-700: #b16631;
  --orange-800: #894f26;
  --orange-900: #693c1d;

  --gray-50: #f4f5f5;
  --gray-100: #dde0e0;
  --gray-200: #cdd1d1;
  --gray-300: #b6bbbc;
  --gray-400: #a8aeaf;
  --gray-500: #929a9b;
  --gray-600: #858c8d;
  --gray-700: #686d6e;
  --gray-800: #505555;
  --gray-900: #3d4141;

  --blue-50: #e6f2ff;
  --blue-100: #b0d6ff;
  --blue-200: #8ac2ff;
  --blue-300: #54a6ff;
  --blue-400: #3395ff;
  --blue-500: #007aff;
  --blue-600: #006fe8;
  --blue-700: #0057b5;
  --blue-800: #00438c;
  --blue-900: #00336b;
}

:root[class~="dark"] {
  --background: #083f3f;
}

@media (prefers-color-scheme: dark) {
  :root {
    /* --background: #0a0a0a;
    --foreground: #ededed; */
  }
}

@screen sm {
  html {
    font-size: 14px; /* Scale down for small screens */
  }
}

@screen lg {
  html {
    font-size: 16px; /* Default size for large screens */
  }
}

html {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: var(--teal-500) transparent; /* For Firefox */
}

* {
  scroll-behavior: smooth; 
}

/* For WebKit browsers like Chrome, Edge, and Safari */
html::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

html::-webkit-scrollbar-thumb {
  background: var(--teal-500); /* Thumb color */
  border-radius: 10px;
}

html::-webkit-scrollbar-track {
  background: transparent; /* Track color */
}

html,
body {
  font-family: "Readex Pro", sans-serif;
  height: 100%;
}

body {
  color: var(--gray-900);
  background: var(--background);
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}
